import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Chip,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { API, apiBase } from "../../api-services";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { set } from "lodash";

export interface IEditScenarioModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  type: any;
  //   getTableData: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "86vw", sm: "35vw" },
  height: "45vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function ShareInstanceModal(props: IEditScenarioModalProps) {
  const { isOpen, onCloseModal, type } = props;
  const { projectId, projectname } = useRouteParams<any>();
  const [instances, setInstances] = React.useState<any[]>([]);
  const [categoryList, setCategoryList] = React.useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>("");
  const [projects, setProjects] = React.useState<any[]>([]);
  const [selectedInstance, setSelectedInstance] = React.useState<string>("");
  const [selectedProject, setSelectedProject] = React.useState<string>("");
  const [saveButtonLoader, setSaveButtonLoader] =
    React.useState<boolean>(false);
  const [saveButtonWOLoader, setSaveButtonWOLoader] =
    React.useState<boolean>(false);
  // Fetch instance list
  const fetchInstances = React.useCallback(() => {
    API.get("/config/get_all_instance_list/", {}, 0)
      .then((res: any) => {
        setInstances(res?.data || []);
      })
      .catch(() => {});
  }, []);

  function extractSubstring(url: string): string {
    // Match the pattern "api-" followed by any characters up to the first dot
    const match = url.match(/api-(.*?)\./);

    // Return the matched group if it exists, otherwise return an empty string
    return match ? match[1] : "";
  }

  // Example usage
  // Output: "tvsm"

  // Fetch project list
  const fetchProjects = React.useCallback((id: any) => {
    API.get(`/config/get_all_project_list/${id}/`, {}, 0)
      .then((res: any) => {
        setProjects(res?.data || []);
      })
      .catch(() => {});
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      fetchInstances();
    }
  }, [isOpen, fetchInstances]);

  const handleSelectChange = (
    event: SelectChangeEvent<string>,
    type: string
  ) => {
    const { value } = event.target;
    if (type === "instance") {
      setSelectedInstance(value);
      fetchProjects(value);
    } else if (type === "project") {
      setSelectedProject(value);
    }
  };

  const handleShare = (typeShare: any) => {
    if (typeShare === "validation") {
      setSaveButtonLoader(true);
    } else {
      setSaveButtonWOLoader(true);
    }
    API.post(
      "/config/download_bom_costing_configuration/", // Update this endpoint if needed
      {
        target_project: selectedProject,
        source_project: projectId,
        instance_id: selectedInstance,
        with_validation: typeShare === "validation" ? true : false,
        source_costing_category:
          type !== "instanceShare" ? type?.id : undefined,
      },
      {},
      0
    )
      .then(() => {
        // getTableData();
        onCloseModal();
        Swal.fire({
          html: `<div>
            <img src="${successIcon}" alt="Success" style="width: 7rem; height: auto;" />
            <p style="color:#007fff;">Configuration shared successfully!</p>
          </div>`,
          confirmButtonText: "Ok",
        });
      })
      .catch((err:any) => {
        setSaveButtonLoader(false);
        setSaveButtonWOLoader(false);
        if(err && err.response && err.response.data && err.response.data[0]){
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err?.response?.data[0] || "Something went wrong!",
          customClass: {
            container: "swal2Container",
          },
        });
      }
      })
      .finally(() => {
        setSaveButtonLoader(false);
        setSaveButtonWOLoader(false);
      });
  };

  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <Box sx={style}>
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0.5rem 1rem",
              borderBottom: "1px solid",
              borderColor: "primary.light",
            }}
          >
            <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>
              Share Configuration
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", color: "primary.main" }}
              onClick={onCloseModal}
            />
          </Box>

          {/* Content */}
          <Box sx={{ padding: "1rem", display: "flex", gap: "1.5rem" }}>
            {/* Left Section - Share From */}
            <Box
              sx={{
                flex: 1,
                padding: "0.5rem",
                border: "1px solid",
                borderColor: "primary.light",
                borderRadius: "4px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                Share From
              </Typography>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
              >
                <Typography variant="body2">
                  <strong>Instance Name: </strong>{" "}
                  {extractSubstring(apiBase) || "Localhost"}
                </Typography>
                <Typography variant="body2">
                  <strong>Project Name: </strong> {projectname || "--"}
                </Typography>
                {type !== "instanceShare" && (
                  <Typography variant="body2">
                    <strong>Category Name: </strong>
                    {type?.commodity_name}
                  </Typography>
                )}
              </Box>
            </Box>

            {/* Right Section - Share To */}
            <Box
              sx={{
                flex: 1,
                padding: "0.5rem",
                border: "1px solid",
                borderColor: "primary.light",
                borderRadius: "4px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                Share To
              </Typography>
              <Box sx={{ display: "grid", gap: 2, gridTemplateColumns: "1fr" }}>
                {/* Instance Dropdown */}
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    shrink
                    sx={{ color: "primary.main" }}
                    id="instance-select-label"
                  >
                    Select Instance
                  </InputLabel>
                  <Select
                    labelId="instance-select-label"
                    id="instance-select"
                    value={selectedInstance}
                    onChange={(e) => handleSelectChange(e, "instance")}
                    MenuProps={MenuProps}
                    size="small"
                    sx={{
                      "&:before": { borderBottomColor: "primary.main" },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                    }}
                  >
                    {instances.map((instance) => (
                      <MenuItem key={instance.id} value={instance.id}>
                        {instance.instance_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Project Dropdown */}
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    shrink
                    sx={{ color: "primary.main" }}
                    id="project-select-label"
                  >
                    Select Project
                  </InputLabel>
                  <Select
                    labelId="project-select-label"
                    id="project-select"
                    value={selectedProject}
                    onChange={(e) => handleSelectChange(e, "project")}
                    MenuProps={MenuProps}
                    size="small"
                    sx={{
                      "&:before": { borderBottomColor: "primary.main" },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                    }}
                  >
                    {projects.map((project) => (
                      <MenuItem key={project.id} value={project.id}>
                        {project.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Category Dropdown */}
              </Box>
            </Box>
          </Box>

          {/* Buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 3,
              borderTop: "1px solid",
              borderColor: "primary.light",
              pt: 2,
              position: "relative",
            }}
          >
            {/* Floating Buttons Container */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 2,
                borderTop: "1px solid",
                borderColor: "primary.light",
                pt: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 1.5,
                  padding: "0.5rem 1rem",
                  backgroundColor: "background.paper",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  borderRadius: "6px",
                }}
              >
                {/* Share with Validation Button */}
                <LoadingButton
                  size="small"
                  variant="contained"
                  loading={saveButtonLoader}
                  disabled={
                    !selectedInstance || !selectedProject || saveButtonWOLoader
                  }
                  onClick={() => handleShare("validation")}
                  sx={{
                    textTransform: "none",
                    fontWeight: "600",
                    whiteSpace: "nowrap", // Ensures text is on one line
                    minWidth: "120px", // Slimmer button width
                  }}
                >
                  Share with Validation
                </LoadingButton>

                {/* Share without Validation Button */}
                <LoadingButton
                  size="small"
                  variant="outlined"
                  loading={saveButtonWOLoader}
                  color="primary"
                  disabled={
                    !selectedInstance || !selectedProject || saveButtonLoader
                  }
                  onClick={() => handleShare("novalidation")}
                  sx={{
                    textTransform: "none",
                    fontWeight: "600",
                    whiteSpace: "nowrap", // Ensures text is on one line
                    minWidth: "120px", // Slimmer button width
                  }}
                >
                  Share without Validation
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
