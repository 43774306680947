import React, { createContext, useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputBase,
  Skeleton,
  styled,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useRouteMatch } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import ShareIcon from "@mui/icons-material/Share";
import swal from "sweetalert";
import styles from "./BomConfigurationLanding.module.scss";

import Typography from "@mui/material/Typography";

import DeleteIcon from "@mui/icons-material/Delete";

import { API } from "../../api-services";
import CostingConfigurationDrawer from "../Costing/CostingConfigurationDrawer";

import BomConfiguration from "./BomConfiguration";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import { AddBomConfigurationModal } from "./AddBomConfigurationModal";
import WorkStationImage from "../../Assets/images/workstation.svg";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";
import { useShareCustomValidationModal } from "./useShareCustomValidationModal";
import BomConfigurationShareModal from "./BomConfigurationShareModal";
import { setItem } from "../../utlis/storage";
import { LoadingButton } from "@mui/lab";
import validated from '../../Assets/images/Validated.svg';
import notvalidated from '../../Assets/images/NotValidated.svg';
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import FolderIcon from '@mui/icons-material/Folder';
import openFolder from '../../Assets/images/svgs/openfolderblue.svg';
import AddBomParameterModals from "./AddBomParameterModal";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { AUTH } from "../../Redux/Services/auth.service";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import { useSelector } from "react-redux";


const label = { inputProps: { "aria-label": "Checkbox demo" } };
export const UserContext = createContext<any>(null)
type Anchor = "top" | "left" | "bottom" | "right";

export interface IRevisionAndProcessProps { }

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'white',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '0px solid rgba(0, 0, 0, .125)',
}));


export function BomConfigurationLanding() {
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId,
    categoryname,
  } = useRouteParams<any>();
  useDocumentTitle("BOM Template Config");
  // const userProfilePic = useSelector(userSelectors.selectAll);
  const [ProductList, setProductList] = useState<any>([]);
  const [ProductLoader, setProductLoader] = useState<any>(false);
  const [CategoryList, setCategoryList] = useState<any>([]);
  const [DownloadLoader, setDownloadLoader] = useState<any>(false);
  const history = useHistory();
  const shareModal: any = useBaseModal();
  const ProductshareModal: any = useBaseModal();
  const [checkvalue, setCheckvalue] = useState<any>([]);
  const [ParentCount, setParentCount] = useState<any>(0);
  const [AddCategorylength, setAddCategorylength] = useState<any>([]);

  const [ValidatedList, setValidatedList] = useState<any>([]);
  const addBomCategoryModal = useBaseModal();
  const [DeleteCounter, setDeleteCounter] = useState<any>(0)
  const [premissionData, setPremissionData] = useState<any>(null)
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [ItemList, setItemList] = useState<any>(null)

  const [tableItem, settableItem] = useState<any>({});
  const [DataList, setDataList] = useState<any>([]);
  const [isLoadingcategory, setIsLoadingcategory] = useState<boolean>(false);
  const [Counter, setCounter] = useState<any>(0);
  const addeditParametreModal = useBaseModal();
  const [validationdata, setValidationdata] = useState<any>();
  const [selectedTab, setSelectedTab] = React.useState('');
  const [expanded, setExpanded] = React.useState<any>('null');
  const accordionRefs = useRef<Array<HTMLDivElement | null>>([]);
  const userProfilePic = useSelector(userSelectors.selectAll); 

  const handleChange =
    (item: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? item?.category : false);
      history.push(
        `/bomconfiguration/${projectId}/${projectname}/${productId}/${item?.category}/${item?.commodity_name}/${validationId}/0`
      );
    };

  useEffect(() => {
    if (expanded) {
      const activeAccordion = accordionRefs.current.find((ref, index) => CategoryList[index].category === expanded);
      if (activeAccordion) {
        activeAccordion.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }
  }, [expanded, CategoryList]);

  useEffect(() => {
    API.get("/xcpep/product_list/", {
      bom_config_product: true,
      project: projectId,
    })
      .then((res: any) => {
        setProductList(res?.data);
      })
      .catch((err: any) => {
        console.log("serverError");
      });
  }, [projectId, DeleteCounter]);

  const CheckBoxHandler = (e: any, Id: any) => {
    e?.stopPropagation();

    if (checkvalue?.includes(Id)) {
      setCheckvalue((prev: any) => prev.filter((item: any) => item !== Id));
      var filterdata =
        checkvalue &&
        checkvalue?.filter((item: any, index: any) => {
          return item !== Id;
        });
      if (filterdata?.length > 0) {
        history.push(
          `/bomconfiguration/${projectId}/${projectname}/${productId}/${categoryId}/${categoryname}/${filterdata?.toString()}/${datatype}`
        );
      } else {
        history.push(
          `/bomconfiguration/${projectId}/${projectname}/${productId}/${categoryId}/${categoryname}/${0}/${datatype}`
        );
      }
    } else {
      setCheckvalue((prev: any) => [...prev, Id]);
      var filterdata1: any = [...checkvalue, Id];
      // @ts-ignore
      var FilterData: any = [...new Set(filterdata1)];

      history.push(
        `/bomconfiguration/${projectId}/${projectname}/${productId}/${categoryId}/${categoryname}/${FilterData?.toString()}/${datatype}`
      );
    }
  };
  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        configuration: true,
        project: projectId,
        module: "BOM Configuration"
      },
      0
    )
      .then((res: any) => {
        setPremissionData(res.data.action);
      })
      .catch((err: any) => { });
  }, [projectId]);
  useEffect(() => {
    setProductLoader(true);
    setCategoryList([]);
    API.get(`/config/template_config/`, {
      product: productId,
      project: projectId,
      module: 6,
    })
      .then((res: any) => {
        setCategoryList(res?.data);
        if (categoryId == 0) {
          history.push(`/bomconfiguration/${projectId}/${projectname}/${productId}/${res?.data[0]?.category}/${res?.data[0]?.commodity_name}/${validationId}/0`)
        }
        setProductLoader(false);
      })
      .catch((err: any) => {
        setProductLoader(false);
        console.log("Server error");
      });
  }, [productId, ParentCount]);


  // useEffect(() => {
  //   if (categoryId !== 0) {
  //     const category = DataList && DataList?.filter((item: any) => item?.category == categoryId)
  //     console.log("categoryId", DataList, category, );
  //     // console.log("categoryId",DataList, category,`/bomconfiguration/${projectId}/${projectname}/${productId}/${categoryId}/${category?.[0]?.commodity_name}/${validationId}/${datatype}`)
  //     history.push(`/bomconfiguration/${projectId}/${projectname}/${productId}/${categoryId}/${category?.[0]?.commodity_name}/${validationId}/${datatype}`);      
  //   }
  // }, [categoryId])

  // console.log("categoryId", categoryId)

  useEffect(() => {
    if (productId !== 0) {
      API.get("/config/matching_config/", { product: productId })
        .then((res: any) => {
          var selectedcheckboxvalue = res?.data?.filter(
            (item: any, index: any) => {
              return item?.match == true;
            }
          );

          var selectedIds = selectedcheckboxvalue?.map(
            (item: any, index: any) => {
              return item?.id;
            }
          );

          const productIdParam: any =
            validationId && typeof validationId == "string"
              ? validationId?.split(",")?.map(Number)
              : [validationId];
          var concatdata: any = [...productIdParam].filter(
            (item: any, index: any) => {
              return item !== 0;
            }
          );

          // @ts-ignore

          var FilterData: any = [...new Set(concatdata)];
          setCheckvalue(FilterData);
          setValidatedList(selectedIds);
        })
        .catch((err: any) => {
          console.log("Server Error");
        });
    }
  }, [productId]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"


    >
      <CostingConfigurationDrawer />
    </Box>
  );

  const ProductHandler = (id: any) => {
    if (id !== productId) {
      history.push(
        `/bomconfiguration/${projectId}/${projectname}/${id}/0/0/${id}/0`
      );
    }

  };

  // /bomconfiguration/:projectId/:projectname/:productId/:categoryId/:categoryname/:validationId/:datatype
  const LockHandlerAction = (item: any, type: any) => {
    if (type == false) {
      API.patch(`/config/template_config/`, {
        lock: type,
        id: item?.id,
        top_vault: productId?.toString(),
        validated: item?.is_validated,
      }).then((res: any) => {
        history.push(`/bomconfiguration/${projectId}/${projectname}/${productId}/${categoryId}/${categoryname}/${validationId}/${datatype}`)
        setParentCount((prev: any) => prev + 1);
      }).catch((err: any) => {
        console.log("Server error")
      })
    }
    if (type == true) {
      API.patch(`/config/template_config/`, {
        lock: type,
        id: item?.id,
        top_vault: productId?.toString(),
        validated: item?.is_validated,
      }).then((res: any) => {
        history.push(`/bomconfiguration/${projectId}/${projectname}/${productId}/${categoryId}/${categoryname}/${validationId}/${datatype}`)
        setParentCount((prev: any) => prev + 1);
      }).catch((err: any) => {
        console.log("Server error")
      })
    }
  }

  const CategoryHandler = (item: any) => {
    history.push(
      `/bomconfiguration/${projectId}/${projectname}/${productId}/${item?.category}/${item?.commodity_name}/${validationId}/0`
    );
  };

  const DeleteCategoryHandler = (e: any, item: any) => {
    history.push(
      `/bomconfiguration/${projectId}/${projectname}/${productId}/${item?.category}/${item?.commodity_name}/${validationId}/0`
    );
    e.stopPropagation();
    swal({
      text: `Are you sure you want to delete ?`,
      icon: "error",
      buttons: ["Cancel", "Yes, Delete"],
    }).then((confirm) => {
      if (confirm) {
        API.delete(
          `/config/template_config/`,
          {
            category: item?.id,
            top_vault: productId?.toString(),
          },
          0
        )
          .then((res: any) => {
            setParentCount((prev: any) => prev + 1);
            setDeleteCounter((prev: any) => prev + 1);
            history.push(
              `/bomconfiguration/${projectId}/${projectname}/${productId}/0/0/${validationId}/0`
            );
          })
          .catch((err: any) => { });
      }
    });
  };

  const workstationhandler = () => {
    history.push(`/work-stations/${projectId}/${projectname}/${productId}`)
  }

  const SwalAlertHandler = useCallback((type: any) => {
    if (type == true) {
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
        <p style="color:#007fff;">Category added successfully
        </p>   
        </div>`,
      });
    } else if (type == false) {
      Swal.fire({
        icon: "error",
        html: `<div>
          <br />
          <p style="color:#007fff;">Server Error
          </p>   
          </div>`,
      });
    }
  }, []);
  const ShareSwalAlertHandler = useCallback((type: any) => {
    if (type == true) {
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
        <p style="color:#007fff;"> Shared successfully
        </p>   
      </div>`,
      });
    } else if (type == false) {
      Swal.fire({
        icon: "error",
        html: `<div>
        <br />
        <p style="color:red;">Server Error
        </p>   
        </div>`,
      });
    }
  }, []);

  React.useEffect(() => {
    API.get("/api/db/category/", {
      top_vault: productId,
      project: projectId,
      module: 6,
    }).then((res: any) => {


      setAddCategorylength(res?.data);
    });
  }, [ParentCount]);
  const Addmodalhandler = () => {
    addBomCategoryModal.open();
  };
  const goBackHandler = () => {
    history.push(`/configurationmain/${projectId}/${projectname}`);
  };
  const handleShare = (e: any, item: any) => {
    e.stopPropagation();
    setItemList(item)
    shareModal.open()
  }
  const ProducthandleShare = (e: any, item: any) => {
    e.stopPropagation();
    setItemList(item)
    ProductshareModal.open()
  }
  const downloadhandler = () => {
    setDownloadLoader(true)
    API.get("/config/parameter_mapping_download/", { top_vault: productId, project: projectId, module: 6 }, 0).then((res: any) => {
      window.open(res?.data)
      setDownloadLoader(false)
    }).catch((err: any) => {
      setDownloadLoader(false)
      console.log("Sever Error")
    })
  }

  useEffect(() => {
    setIsLoadingcategory(true);
    API.get("/config/parameter_datatype_config/", {
      product: productId,
      module: 6,
      category: categoryId,
    })
      .then((res: any) => {
        // setDataList(res?.data);
        // setIsLoadingcategory(false);

        // Sort the received data by parameter_group in ascending order
        let sortedData = res?.data.sort((a: any, b: any) => a.parameter_group - b.parameter_group);
        // sortedData.push({"parameter__data_type": "Cost Model",
        // "count": 0,
        // "parameter_group": 3 })

        setDataList(sortedData);
        setIsLoadingcategory(false);
      })
      .catch((err: any) => {
        console.log("Sever error");
        setIsLoadingcategory(false);
      });
  }, [categoryId, Counter]);

  const Parameterhandler = () => {
    addeditParametreModal.open();
  };

  const handleChangeCheckBox = (type?: any) => {
    if (type == "checkbox") {
      const checkboxvalue =
        CategoryList &&
        CategoryList?.filter((item: any, index: any) => {
          return item?.category == categoryId;
        })[0];
      API.patch("/config/template_config/", {
        id: checkboxvalue?.id,
        validated: !validationdata,
        top_vault:
          validationId == 0 ? productId?.toString() : validationId?.toString(),
      })
        .then((res: any) => {
          setValidationdata(res?.data?.validated);
          setParentCount((prev: any) => prev + 1);
          setDeleteCounter((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          setValidationdata(false);

          Swal.fire({
            icon: "error",
            html: `<div>
      <br />
      <p style="color:red;">${data[0]}</p>   
       </div>`,
          });
        });
    } else {
      const checkboxvalue =
        CategoryList &&
        CategoryList?.filter((item: any, index: any) => {
          return item?.category == categoryId;
        })[0];
      API.patch("/config/template_config/", {
        id: checkboxvalue?.id,
        validated: false,
        top_vault:
          validationId == 0 ? productId?.toString() : validationId?.toString(),
      })
        .then((res: any) => {
          setValidationdata(res?.data?.validated);
          setParentCount((prev: any) => prev + 1);
          setDeleteCounter((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          setValidationdata(false);

          Swal.fire({
            icon: "error",
            html: `<div>
      <br />
      <p style="color:red;">${data[0]}</p>   
       </div>`,
          });
        });
    }
  };

  const DataTypeHandler = (name: any) => {
    // setExpanded(name !== expanded ? name : null);

    // setTabVale(name);
    setSelectedTab(name)
    history.push(
      `/bomconfiguration/${projectId}/${projectname}/${productId}/${categoryId}/${categoryname}/${validationId}/${name?.parameter__data_type}`
    );
  };


  return (
    <div>
      <Box sx={{ margin: "0 0.5rem" }}>
        {addeditParametreModal.isOpen && (
          <AddBomParameterModals
            isOpen={addeditParametreModal.isOpen}
            onCloseModal={addeditParametreModal.close}
            setParentCount={setParentCount}
            setCount={setCounter}
            handleChangeCheckBox={handleChangeCheckBox}
            showAll={false}
          />
        )}

        {addBomCategoryModal.isOpen && (
          <AddBomConfigurationModal
            setDeleteCounter={setDeleteCounter}
            onCloseModal={addBomCategoryModal.close}
            isOpen={addBomCategoryModal.isOpen}
            SwalAlertHandler={SwalAlertHandler}
            setParentCount={setParentCount}
          />
        )}

        {shareModal.isOpen &&
          <BomConfigurationShareModal
            isOpen={shareModal.isOpen}
            onCloseModal={shareModal.close}
            ShareSwalAlertHandler={ShareSwalAlertHandler}
            type={"category"}
            nameHeader={ItemList}
            setParentCount={setParentCount}
            setDeleteCounter={setDeleteCounter}
          />}
        {ProductshareModal.isOpen &&
          <BomConfigurationShareModal
            isOpen={ProductshareModal.isOpen}
            onCloseModal={ProductshareModal.close}
            ShareSwalAlertHandler={ShareSwalAlertHandler}
            type={"productLevel"}
            nameHeader={ItemList}
            setParentCount={setParentCount}
            setDeleteCounter={setDeleteCounter}
          />}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: '1rem',
            // justifyContent: "space-between",
            width: "100%",
          }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              width: { lg: "23vw", xl: "21vw" },
            }}>
            <IconButton
              sx={{ cursor: "pointer" }}
              title="Go Back"
              onClick={() => goBackHandler()}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1rem",
                whiteSpace: "nowrap",
              }}>
              {projectname} (BOM Template Configuration)
            </Typography>
          </Box>


          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1.5rem",
              justifyContent: "space-between",
              paddingRight: "1rem",
              width: "100%",
            }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "1.5rem",
              }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: { lg: '71vw', xl: "75vw" },
                  gap: "0.5rem",
                  padding: '0.3rem 0.5rem'
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3">
                {ProductList &&
                  ProductList?.map((item: any, index: any) => {
                    return (
                      <>
                        <Box
                          onClick={() => ProductHandler(item?.id)}
                          sx={{
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
                            backgroundColor:
                              item?.id == productId
                                ? "primary.light"
                                : "white",
                            display: "flex",
                            width: "fit-content",
                            borderRadius: "4px",
                            alignItems: "center",
                            padding: '0 0.5rem',
                            columnGap: '0.5rem',
                          }}>
                          <img src={item?.template_config ? validated : notvalidated} style={{ width: '1.5rem' }} />
                          {ValidatedList?.includes(item?.id) &&
                            <Checkbox
                              style={{ padding: '3px' }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 18 }, color: "primary.main" }}
                              {...label}
                              checked={
                                checkvalue.includes(item?.id) ? true : false
                              }
                              onChange={(e: any) =>
                                CheckBoxHandler(e, item?.id)
                              }
                            />}
                          <Typography
                            sx={{ fontSize: "1rem", cursor: "pointer", whiteSpace: 'nowrap' }}>
                            {item?.name} ({item?.product_id})
                          </Typography>

                          {item?.template_config ? <span style={{ display: "flex", alignItems: "center", padding: '0.5rem', }}>
                            {/* <IconButton disabled={
                               premissionData && premissionData?.includes("C") ? false : true} sx={{ color: "#007fff" }}> */}
                            {premissionData && premissionData?.includes("C") ?

                              <ShareIcon onClick={(e: any) => ProducthandleShare(e, item)} sx={{ opacity: 1, fontSize: '1.5rem', cursor: "pointer" }} /> :

                              <ShareIcon sx={{ opacity: '0.3', fontSize: '1.5rem' }} />
                            }
                            {/* </IconButton> */}
                          </span>
                            : <span style={{ width: '0.5rem', height: "2.5rem" }}></span>}
                        </Box>
                      </>
                    );
                  })}
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "1rem", paddingRight: { lg: '0rem', xl: '1rem' } }}>
            <img
              style={{ width: "1.5rem", cursor: "pointer" }}
              src={WorkStationImage}
              title="Work station"
              onClick={() => workstationhandler()}
            />
            <LoadingButton onClick={() => downloadhandler()} title="Download" sx={{
              minWidth: "1rem", cursor: "pointer",
              padding: '0.1rem 0.5rem',
              '.MuiCircularProgress-root': {
                color: 'primary.main',
              },
            }} loading={DownloadLoader} startIcon={<FileDownloadIcon sx={{ marginRight: '-1rem', fontSize: '1.7rem !important' }} />} />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}>
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}>
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}>Select Configuration</Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}> Select Configuration</Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={{
            width: "97%",
            display: "flex",
            alignItems: "flex-start",
            // paddingRight: "1rem",
          }}>
          <Box>
            <Box sx={{ padding: '0.3rem 1rem', textAlign: 'center' }}>
              {premissionData && premissionData?.includes("C") ? <Button
                startIcon={<AddIcon sx={{ marginRight: '-0.5rem' }} />}
                sx={{
                  width: "100%",
                  lineHeight: 1,
                  borderRadius: '3rem',
                  cursor: 'pointer',
                  // AddCategorylength?.length == 0 ? "not-allowed" : "pointer",
                  "&:hover": {
                    transform: 'Scale(1.05)',
                    transition: 'transform 0.5s ease',
                  }
                }}
                variant="contained"
                onClick={() => Addmodalhandler()}>
                ADD BOM Category
              </Button> : <Button
                disabled={true}
                startIcon={<AddIcon sx={{ marginRight: '-0.5rem' }} />}
                sx={{
                  width: "100%",
                  lineHeight: 1,
                  borderRadius: '3rem',
                }}
                variant="contained">
                ADD BOM Category
              </Button>}
            </Box>
            <Box
              sx={{
                height: { lg: "81vh", xl: "84vh" },
                width: { lg: "20vw", xl: "18vw" },
                padding: "0.5rem",
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                rowGap: "0.5rem",
              }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {ProductLoader ? (
                <Box sx={{ width: '100%' }}>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="1.6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="1.6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="1.6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="1.6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="1.6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="1.6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="1.6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="1.6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="1.6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="1.6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="1.6rem"></Skeleton>
                </Box>
              ) : (
                <>
                  {CategoryList &&
                    CategoryList?.map((item: any, index: any) => {
                      return (
                        <>
                          <Accordion expanded={item?.category === categoryId} onChange={handleChange(item)} sx={{ width: '100%', border: 'none', }}
                            ref={(el) => (accordionRefs.current[index] = el)}>
                            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', }}>
                              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                                sx={{ padding: '0', minHeight: '24px', width: '100%', }}
                                expandIcon={
                                  item?.category === categoryId ? (<img src={openFolder} style={{ height: '1.2rem', }} />)
                                    : (<FolderIcon sx={{ fontSize: '1.5rem', color: 'primary.main', }} />)
                                }>
                                <Box sx={{ marginLeft: '0.3rem', width: '100%', display: 'flex', alignItems: 'center', }}>
                                  <Typography title={item?.commodity_name} sx={{ width: '100%', lineHeight: '1.1', color: 'text.primary' }}>
                                    {item?.commodity_name} {item?.is_leaf ? (
                                      <span style={{ fontSize: "1rem" }}>
                                        (Leaf)
                                      </span>
                                    ) : (
                                      <span style={{ fontSize: "1rem" }}>
                                        (Non Leaf)
                                      </span>
                                    )}
                                  </Typography>
                                  <Typography sx={{ padding: "0 2px", color: 'text.primary' }}>({item?.parameter_count})</Typography>
                                </Box>
                              </AccordionSummary>
                              <Box
                                sx={{
                                  display: "grid",
                                  gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                  columnGap: "0.5rem",
                                  whiteSpace: 'nowrap',
                                  alignItems: "center",
                                  padding: "0 3px",
                                  width: '9.5rem',
                                }}
                              >
                                <Box>
                                  {item?.parameter_count == 0 && (<Box>
                                    <>
                                      {premissionData &&
                                        premissionData?.includes("D") ? (
                                        <DeleteIcon
                                          titleAccess="Delete"
                                          sx={{ cursor: "pointer", color: 'red' }}
                                          onClick={async (e: any) => {
                                            DeleteCategoryHandler(e, item);
                                          }}
                                        />
                                      ) : (
                                        <DeleteIcon
                                          titleAccess="Delete"
                                          sx={{ opacity: "0.3", color: 'red' }}
                                        />
                                      )}
                                    </>
                                  </Box>)}
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                  {item?.is_validated && (
                                    // <IconButton
                                    //   disabled={
                                    //     premissionData && premissionData?.includes("C") ? false : true}
                                    // >
                                    <ShareIcon
                                      sx={{
                                        cursor: 'pointer',
                                        fontSize: "1.5rem",
                                        // opacity: premissionData && premissionData?.includes("C") ? 1 : '0.3'
                                      }}
                                      onClick={(e: any) => handleShare(e, item)}
                                    />
                                  )
                                    // </IconButton>
                                  }
                                </Box>

                                <Box>
                                  {(AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) && (item?.lock && item?.is_validated) ? (
                                    <IconButton
                                      sx={{
                                        padding: "0",
                                        cursor: "pointer",
                                        color: "green",
                                      }}
                                      onClick={() => {
                                        swal({
                                          title: "Unlock?",
                                          text: "Are you sure you want to unlock?",
                                          buttons: ["Cancel", "Yes"],

                                          icon: "error",
                                        }).then((confirm) => {
                                          if (confirm) {
                                            LockHandlerAction(item, false)
                                          }
                                        });

                                      }
                                      }
                                    >
                                      <LockIcon sx={{ color: "green" }} />
                                    </IconButton>
                                  ) : ( (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) && (item?.is_validated && <IconButton
                                    sx={{
                                      padding: "0",
                                      cursor: "pointer",
                                      color: "color:'red",
                                    }}
                                    onClick={() => {
                                      swal({
                                        title: "Lock?",
                                        text: "Are you sure you want to Lock?",
                                        buttons: ["Cancel", "Yes"],

                                        icon: "error",
                                      }).then((confirm) => {
                                        if (confirm) {
                                          LockHandlerAction(item, true)
                                        }
                                      });

                                    }
                                    }
                                  >
                                    <LockOpenIcon sx={{ color: 'red' }} />
                                  </IconButton>)
                                  )}
                                </Box>
                                <img
                                  src={
                                    item?.is_validated
                                      ? validated
                                      : notvalidated
                                  }
                                  style={{ width: "1.5rem" }}
                                />
                              </Box>
                            </Box>
                            <AccordionDetails>
                              {isLoadingcategory ? (
                                <>
                                  <Skeleton />
                                  <Skeleton />
                                  <Skeleton />
                                  <Skeleton />
                                  <Skeleton />
                                  <Skeleton />
                                </>
                              ) : (
                                <Box
                                  sx={{
                                    marginLeft: '1.3rem',
                                    marginRight: '0.5rem',
                                    borderLeft: '1px solid',
                                    borderColor: 'primary.main',
                                  }}>
                                  {DataList &&
                                    DataList?.map(
                                      (
                                        item: any,
                                        indx: any
                                      ) => {
                                        return (
                                          <>
                                            <Typography
                                              sx={{
                                                backgroundColor:
                                                  item?.parameter__data_type === datatype
                                                    ? "primary.main"
                                                    : item?.parameter_group === 1
                                                      ? "#cef0ff"
                                                      : item?.parameter_group === 2
                                                        ? "#d0f1aa"
                                                        : item?.parameter_group === 3
                                                          ? "#fbdddd"
                                                          : item?.parameter_group === 4
                                                            ? "#ffee93"
                                                            : "#d1cfe2",
                                                color:
                                                  item?.parameter__data_type === datatype
                                                    ? "white"
                                                    : "text.primary",
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontSize: '1rem',
                                                columnGap: '2px',
                                                cursor: 'pointer',
                                                marginBottom: '3px',
                                                padding: item?.parameter__data_type === datatype ? '2px 0.3rem 2px 0' : '0rem 0.3rem 0 0',
                                                border: item?.parameter__data_type === datatype ? '2px solid #000' : '',
                                                borderRadius: item?.parameter__data_type === datatype ? '5px' : '',
                                              }}
                                              onClick={(e: any) => { DataTypeHandler(item) }}
                                            >
                                              {' '}
                                              <span style={{ whiteSpace: 'nowrap' }}>--</span>
                                              <span style={{ lineHeight: '1.2', width: '85%', textTransform: 'capitalize' }}>{
                                                item?.parameter__data_type
                                              }</span>{' '}
                                              ({item?.count})
                                              {/* {!premissionData && premissionData?.includes("U") ? (
                                                <AddCircleOutlineIcon sx={{ marginLeft: "0.2rem", opacity: 0.3 }} />
                                              ) : (
                                                <AddCircleOutlineIcon
                                                  titleAccess="Add BOM Parameter"
                                                  sx={{
                                                    marginLeft: "0.2rem",
                                                    cursor: "pointer",
                                                    opacity: validationId == "true" ? 0.5 : 1,
                                                  }}
                                                  onClick={() => {
                                                    if (validationId != "true") {
                                                      Parameterhandler();
                                                    }
                                                  }}
                                                />
                                              )} */}
                                            </Typography>
                                          </>
                                        );
                                      }
                                    )}
                                </Box>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </>
                      );
                  })}
                </>
              )}
            </Box>
          </Box>
          <Box sx={{ width: { lg: '76vw', xl: "80vw" } }}>
            <UserContext.Provider value={premissionData}>
              <BomConfiguration
                setDeleteCounter={setDeleteCounter}
                CategoryList={CategoryList && CategoryList}
                ParentCount={ParentCount}
                setParentCount={setParentCount}
                Counter={Counter}
                setCounter={setCounter}
              />
            </UserContext.Provider>
          </Box>
        </Box>
      </Box>
    </div >
  );
}
